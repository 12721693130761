import { useEffect, useRef, useState } from 'react';
import { FcComments, FcLike } from 'react-icons/fc';
import { RxEnter } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { backendURLFile, streamingUrlFile } from '../../../../../Config/baseUrl';
import { ETypeVideo } from "../../../../../shared/enum";
import { AppDispatch } from '../../../../../Store/Store';
import { setMessageNull } from '../../../../../Toolkit/authAction';
import MainModal from '../../../../Admin/Components/Modals/MainModal';
interface paymentInfo {
  user: any;
  montant: number;
  phoneNumber: number;
  titulaire: string;
  state: boolean;
}

const VideoPlayer = (props: any) => {
  const videoRef = useRef<any>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.load(); // Recharge la vidéo lorsque src change
    }
  }, [props.src]);

  return (
    <video ref={videoRef} className="w-full h-5/6" controls>
      <source src={props.src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};


export default function Video(props: any) {
  /**
   * VARIALES
   */
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalPayment, setOpenModalPayment] = useState<boolean>(false);
  const [montant, setMontant] = useState<number>(0);
  const [phone, setPhone] = useState<number>(0);
  const [titulaire, setTitulaire] = useState<string>('');
  const user: any = localStorage.getItem('user');
  const userPayment: any = localStorage.getItem('paymentInfo');
  const [validated, setValidated] = useState<boolean>(false);
  const [isAbonne, setAbonne] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [tokenPlay, setTokenPlay] = useState<string | null>(null)
  const [linkVideo, setLinkVideo] = useState<string | null>(null)
  const [typeVideo, setTypeVideo] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    const lastPayment: paymentInfo[] = JSON.parse(userPayment) || [];
    const userConnected = JSON.parse(user);
    lastPayment.map((item) => {
      if (item.user.id === userConnected.id) {
        item.state ? setValidated(true) : setAbonne(true);
      } else {
        setValidated(false);
      }
    });
  }, []);

  useEffect(() => {
    if (props.type) {
      setTypeVideo(props.type)
    }
  }, [props.type])

  useEffect(() => {

    setLoading(true)
    if (!props.link) return;
    fetchToken()
    setLinkVideo(props?.link?.split('/').pop())

    setLoading(false)
    //setLinkVideo
  }, [props.link])
  /**
   * FUNCTIONS
   */
  const handleFilmIntegrale = () => {
    setOpenModal(!openModal);
  };

  const handlePayment = (money: number) => {
    setOpenModal(false);
    setOpenModalPayment(!openModalPayment);
    setMontant(money);
  };

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/\D/g, '');
    setPhone(filteredValue);
  };

  const handleTitulaire = (event: any) => {
    setTitulaire(event.target.value);
  };

  const handleSubmit = () => {
    const tmpAbonnement: paymentInfo[] = JSON.parse(userPayment) || [];
    const paymentInfo: paymentInfo = {
      user: JSON.parse(user),
      montant: montant,
      phoneNumber: phone,
      titulaire: titulaire,
      state: false
    };
    tmpAbonnement.push(paymentInfo);
    const uniqueArray = tmpAbonnement.filter(
      (obj, index, self) => index === self.findIndex((t) => t.user.id === obj.user.id)
    );
    localStorage.setItem('paymentInfo', JSON.stringify(uniqueArray));
    setOpenModalPayment(false);
  };

  const handleViewFilmIntegrale = () => {
    navigate('');
  };

  const fetchToken = async () => {
    const response = await fetch(`${streamingUrlFile}/generate-token/play`);
    const data = await response.json();
    setLinkVideo(props?.link?.split('/').pop());
    setTokenPlay(data.token)
    return data.token;
  };

  useEffect(() => {
    const fetchAndSetToken = async () => {
      if (props?.link) {
        await fetchToken();
      }
    };
    fetchAndSetToken();
  }, [props]);


  return (
    <>
      <div className="w-full flex flex-col m-2 sm:h-[30rem] max-md:mb-64">
        <div>
          {props.type == 'Teasers' && (
            <>
              <article className="text-wrap">
                <h3 className="text-2xl font-semibold text-pink-500">{props.movie.title}</h3>
                <h5 className="text-sm font-semibold mt-5">Description :</h5>
                <p className="text-xs p-1">{props.movie.post.legend}</p>
              </article>
              <div className="flex flex-row">
                <img className="w-2/5 h-32 float-left p-2" src={`${backendURLFile}/${props.movie.image}`} />
                <div className="p-3">
                  <ul>
                    <li>{`Genre : ${props.movie.category}`}</li>
                    <li>{`Durée : ${props.movie.duration}`}</li>
                    <li>{`Date de sortie : ${props.movie.date}`}</li>
                    <li>{`Directeur : ${props.movie.director}`}</li>
                  </ul>
                </div>
              </div>
              {validated && (
                <div
                  onClick={handleViewFilmIntegrale}
                  className={`p-2 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
                >
                  <div>
                    <h2 className="text-base font-semibold">Voir Film</h2>
                  </div>
                </div>
              )}
              {!isAbonne && (
                <div
                  onClick={handleFilmIntegrale}
                  className={`p-2 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
                >
                  <div>
                    <h2 className="text-base font-semibold">Voir Film</h2>
                  </div>
                </div>
              )}
            </>
          )}
        </div>


        <div
          className="w-full h-5/6 pt-2
							rounded-xl"
        > {
            loading ? (
              <>Loading</>
            ) : linkVideo && tokenPlay ? (
              <VideoPlayer
                src={`${streamingUrlFile}/video/${typeVideo.toLowerCase()}/${linkVideo}/${tokenPlay}`}
              />
            ) : (
              <>Erreur : La vidéo ne peut pas être chargée</>
            )
          }

          <div className="h-1/3 text-base text-slate-100 flex flex-row justify-between">
            <div>
              <p>{props.type != 'Musiques' ? props.title : `${props.movie.artists} ${props.movie.title}`}</p>
              <p className="text-sm font-semibold pt-2">
                {props.type != 'Teasers' ? props.description : props.movie.title}
              </p>
              <p>{props.type == 'Musiques' && props.movie.ranking}</p>
            </div>
            <div className="text-base pt-3 flex flex-row">
              <span className="cursor-pointer flex flex-row">
                {0} <FcComments size={23} />
              </span>
              <span className="mx-10 cursor-pointer flex flex-row">
                {0} <FcLike size={23} />
              </span>
            </div>
          </div>
          <div className="text-base">
            <p>@ Utilisateur</p>
            <p>Commentaire de l'utilisateur</p>
          </div>
        </div>
      </div>
      <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
        <div className="inline-block sm:w-4/5 md:w-3/5 lg:w-3/5 w-3/5 align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
          <p className="text-4xl font-extrabold text-yellow-500">Abonnement streaming</p>
          <div className="flex flex-row justify-between py-20 px-20">
            <div>
              <p className="text-4xl font-extrabold p-3">Home</p>
              <p className="text-lg font-extrabold">249 000 ar TTC / mois</p>
              <p className="text-yellow-400">12 mois</p>
              <div
                onClick={() => {
                  handlePayment(249000);
                }}
                className={`p-2 my-4 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
              >
                <div>
                  <h2 className="text-base font-semibold">Abonnee</h2>
                </div>
              </div>
            </div>
            <div>
              <p className="text-4xl font-extrabold p-3">Family</p>
              <p className="text-lg font-extrabold">149 000 ar TTC / mois</p>
              <p className="text-yellow-400">12 mois</p>
              <div
                onClick={() => {
                  handlePayment(149000);
                }}
                className={`p-2 my-4 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
              >
                <div>
                  <h2 className="text-base font-semibold">Abonnee</h2>
                </div>
              </div>
            </div>
            <div>
              <p className="text-4xl font-extrabold p-3">Go</p>
              <p className="text-lg font-extrabold">99 000 ar TTC / mois</p>
              <p className="text-yellow-400">12 mois</p>
              <div
                onClick={() => {
                  handlePayment(99000);
                }}
                className={`p-2 my-4 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
              >
                <div>
                  <h2 className="text-base font-semibold">Abonnee</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainModal>
      <MainModal modalOpen={openModalPayment} setModalOpen={setOpenModalPayment}>
        <div className="inline-block align-middle w-auto p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
          <p className="text-xl font-extrabold text-yellow-500">Payement Mvola</p>
          <form className="flex flex-col py-10">
            <div>
              <label className="text-xl font-extrabold p-3">Montant</label>
              <input
                name="montant"
                required
                type="text"
                value={montant}
                disabled={true}
                className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
              />
            </div>
            <div className="pt-5">
              <label className="text-xl font-extrabold p-3">N Telephone</label>
              <input
                name="phoneNumber"
                required
                value={phone}
                type="text"
                onChange={handleInputChange}
                placeholder="Saisissez ici votre numero de telephone"
                className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
              />
            </div>
            <div className="pt-5">
              <label className="text-xl font-extrabold p-3">Titulaire Mvola</label>
              <input
                name="titulaire"
                required
                type="text"
                value={titulaire}
                onChange={handleTitulaire}
                placeholder="nom inscript sur votre puce"
                className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
              />
            </div>
            <div className="text-sm w-full mt-4">
              <div
                onClick={handleSubmit}
                className={`p-2 my-4 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
              >
                <RxEnter /> Envoyer
              </div>
            </div>
          </form>
        </div>
      </MainModal>
      {!validated && (
        <div
          className="fixed right-0  w-96 bg-zinc-900 bg-opacity-90 p-4 text-base text-danger-700 gap-4 flex justify-center hover:bg-opacity-90"
          role="alert"
        >
          {/* <div className="flex flex-row">
            <div>
              <img className="h-14 w-14" src={process.env.PUBLIC_URL + '/Images/casque.png'} />
            </div>
            <div className="text-[10px] flex flex-col">
              <p className="text-gray-100 text-base font-semibold">Votre abonnement est en cours de traitement,</p>
              <p className="text-gray-100 text-base font-semibold">Veuillez patient</p>
              <div className="flex flex-row justify-between">
                <p
                  className="text-red-700 hover:text-red-500 cursor-pointer"
                  onClick={() => dispatch(setMessageNull())}
                >
                  Fermer
                </p>
              </div>
            </div>
          </div>
          <span className="z-50 bg-red-500 text-white text-xs font-semibold px-2 rounded-full absolute top-0 right-96">
            1
          </span> */}
        </div>
      )}
    </>
  );
}
