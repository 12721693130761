import { parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { CiViewList } from 'react-icons/ci';
import { FaSearch } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { TfiLayoutGrid4 } from 'react-icons/tfi';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Store/Store';
import { GetAllevents, setMessageNull, seterrornull } from '../../../../Toolkit/EventAction';
import Sidebar from '../../../Admin/Components/Sidebar';
import { EventList } from '../Components/EventTools/EventListShow';
import Pagination from '../Components/Pagination/Pagination';
import EventItem from "./component/EventItem";
import { EventShow } from "../Components/EventTools/Eventshow";
import EventDetail from "./component/EventDetail";
import { useNavigate } from "react-router-dom";

function EventListClient() {
  // Redux
  const message = useSelector((state: any) => state.event.message);
  const error = useSelector((state: any) => state.event.error);
  const loading = useSelector((state: any) => state.event.loading);
  const dispatch = useDispatch<AppDispatch>();
  const events = useSelector((state: any) => state.event.lists);

  /**
   * VARIABLE
   */
  const [searchTerm, setSearchTerm] = useState('');
  const [dataAvaible, setDataVailable] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isGridVisible, setGridVisible] = useState(false);
  const [currentTableData, setCurrentTableData] = useState<any>([]);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const navigate = useNavigate();

  // Functions
  const FlowOut = (data: any) => {
    let dataEvent: any[] = [];
    const datenow = new Date();
    data.forEach((event: any) => {
      const dateEvt = parse(event.date, 'd MMMM yyyy', new Date(), { locale: fr });
      if (datenow <= dateEvt) {
        dataEvent.push(event);
      }
    });
    return dataEvent;
  };

  const dataFilter = (term: string) => {
    let array: any[] = [];
    dataAvaible.filter((item: any) => {
      if (
        item.place?.toLowerCase().includes(term?.toLowerCase()) ||
        item.post.legend?.toLowerCase().includes(term?.toLowerCase()) ||
        item.date?.toLowerCase().includes(term?.toLowerCase())
      ) {
        array.push(item);
      }
    });
    return array;
  };

  const resultSearch = dataFilter(searchTerm);

  const toggleView = () => {
    setGridVisible(!isGridVisible);
  };

  // Lifecycle: useEffect for filtering available events
  useEffect(() => {
    if (events.length > 0) {
      setDataVailable(FlowOut(events));
    }
  }, [events]);

  // Lifecycle: useEffect for fetching events
  useEffect(() => {
    dispatch(GetAllevents());
  }, []);

  // Constants
  const PageSize = 8;

  // Lifecycle: useEffect for handling pagination
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(events.slice(firstPageIndex, lastPageIndex));
  }, [events, currentPage]);

  /**
   * FUNCTION
   */
  const handleClickedEvent = (event: any) => {
    // setSelectedEvent(event)
    setIsShowDetail(true)
    navigate(`/dashboard/client/event/${event.id}`)
  }

  return (
    <>
      <Sidebar>

        {isShowDetail ? <></> : <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Liste des évenements</h2>
            <div className="col-span-3 flex-btn gap-2">
              <form className="w-full text-sm bg-dryGray rounded flex-btn gap-4">
                <button className="bg-subMain w-12 flex-colo h-12 rounded text-white">
                  <FaSearch />
                </button>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black"
                  placeholder="Rechercher un évenements"
                />
              </form>
              <div onClick={toggleView} className="cursor-pointer mx-6">
                {isGridVisible ? <TfiLayoutGrid4 size={34} /> : <CiViewList size={40} />}
              </div>
            </div>
          </div>
          <div className="flex justify-center m-15">
            {loading ? (
              <CirclesWithBar
                height="300"
                width="300"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            ) : <div>
              {events &&
                searchTerm == '' &&
                (isGridVisible ? <EventList data={currentTableData} /> :

                  <div className="grid gap-x-8 gap-y-4 grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1">
                    {
                      currentTableData?.map((event: any) => (
                        <EventItem event={event} handleClickedEvent={handleClickedEvent} />

                      ))
                    }
                  </div>)}
              {events && searchTerm != '' && <EventShow data={resultSearch} handleClickedEvent={handleClickedEvent} />}
            </div>}
          </div>

          <div>
            <Pagination
              className="flex w-full justify-center"
              currentPage={currentPage}
              totalCount={dataAvaible.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
        </div>}
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
      {error && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{error}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default EventListClient;
