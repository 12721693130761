import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineHeart, AiOutlineMessage, AiOutlineShareAlt, AiOutlineClose, AiFillHeart } from "react-icons/ai"; // Import des icônes
import { FaArrowUp, FaArrowDown } from "react-icons/fa";  // Import des flèches verticales
import "./displayShort.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/Store";
import { GetAllshort } from "../../../../Toolkit/ShortAction";
import { useNavigate } from 'react-router-dom'; // Si tu veux rediriger après fermeture
import { streamingUrlFile } from "../../../../Config/baseUrl";
import { tootgleReactionPost } from "../../../../Toolkit/CommentAction";



const VideoPlayer = (props: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.load();
    }
  }, [props.src]);

  // Fonction pour gérer le clic sur la vidéo
  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();  // Mettre la vidéo en pause
        setIsPlaying(false);       // Mettre à jour l'état
      } else {
        videoRef.current.play();   // Relancer la vidéo
        setIsPlaying(true);        // Mettre à jour l'état
      }
    }
  };

  return (
    <video ref={videoRef}
      onClick={handleVideoClick}
      className="short-video"
      autoPlay
      loop>
      <source src={props.src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};



const DisplayShort: React.FC = () => {
  /**
   * Redux
   */
  const dispatch = useDispatch<AppDispatch>();
  const shorts = useSelector((state: any) => state.short.lists);

  /**
   * LOCAL STATE
   */
  const [currentShortIndex, setCurrentShortIndex] = useState<number>(0);
  const navigate = useNavigate();
  const [tokenPlay, setTokenPlay] = useState<string>('');
  const [linkVideo, setLinkVideo] = useState<string>('')
  const [reaction, setReaction] = useState<number>(0)
  const [liked, setLiked] = useState(false);


  /**
   * LIFECYCLE
   */
  useEffect(() => {
    dispatch(GetAllshort());
  }, [dispatch]);

  useEffect(() => {
    if (shorts.length > 0 && shorts[currentShortIndex]) {
      fetchToken(shorts[currentShortIndex].post.files[0]?.split('/').pop())
    }
  }, [currentShortIndex, shorts])


  // Protéger contre les tableaux vides et s'assurer que l'index actuel est valide
  const currentShort = shorts.length > 0 && shorts[currentShortIndex] ? shorts[currentShortIndex] : null;


  useEffect(() => {
    if (currentShort) {
      const userStorage = localStorage.getItem('user');
      const user = JSON.parse(userStorage || '{}');
      const reaction = currentShort?.post?.reaction?.usersId ? JSON.parse(currentShort.post.reaction.usersId).length : 0
      setReaction(reaction)
      setLiked(currentShort?.post?.reaction?.usersId.includes(user.id))

    }
  }, [currentShort])

  /**
   * HANDLERS for Next, Previous, and Close
   */
  const handleNext = () => {
    if (shorts.length > 0 && currentShortIndex < shorts.length - 1) {
      setCurrentShortIndex(currentShortIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (shorts.length > 0 && currentShortIndex > 0) {
      setCurrentShortIndex(currentShortIndex - 1);
    }
  };

  const handleClose = () => {
    // Rediriger ou cacher la vidéo, par exemple en naviguant ailleurs
    navigate('/dashboard/client');  // Redirige vers la page d'accueil ou où tu veux
  };


  const handleComment = () => {
    console.log("🚀 ~ handleComment ~ handleComment:", handleComment);
  }

  const handleLike = () => {
    const userStorage = localStorage.getItem('user');
    const user = JSON.parse(userStorage || '{}');

    const currentPost = shorts[currentShortIndex];

    if (!user || !currentPost?.postId || !currentPost?.post?.reaction?.usersId) return;
    dispatch(tootgleReactionPost(currentPost?.postId)).then((data: any) => {
      const reaction = data.payload.reaction?.usersId ? JSON.parse(data.payload.reaction.usersId).length : 0
      setReaction(reaction)
      setLiked(data.payload.reaction?.usersId.includes(user.id))
    });
  };

  /**
   * API
   */
  const fetchToken = async (link: string) => {
    const response = await fetch(`${streamingUrlFile}/generate-token/play`);
    const data = await response.json();
    setLinkVideo(link);
    setTokenPlay(data.token)
    return data.token;
  };

  return (
    <div className="container-short">
      {/* Bouton "Fermer" en haut à gauche */}
      <button className="close-button" onClick={handleClose}>
        <AiOutlineClose size={30} />
      </button>

      <div className="video-container">
        {/* Vérification que la vidéo existe avant de l'afficher */}
        {currentShort && currentShort.post.files[0] ? (
          <VideoPlayer
            src={`${streamingUrlFile}/video/shorts/${linkVideo}/${tokenPlay}`}
          />
        ) : (
          <p>Aucune vidéo disponible.</p>
        )}


        {/* Icônes de J'aime, Commentaire, Partager, avec Next/Previous */}
        <div className="sidebar">
          <button className="nav-button" onClick={handlePrevious} disabled={currentShortIndex === 0}>
            <FaArrowUp size={30} />
          </button>

          <div className="icon-wrapper">
            {
              liked ?
                <AiFillHeart size={40} onClick={handleLike} />
                : <AiOutlineHeart size={40} onClick={handleLike} />
            }
            <p>{reaction}</p>
          </div>

          <div className="icon-wrapper">
            <AiOutlineMessage size={40} onClick={handleComment} />
            <p>Commentaires</p>
          </div>

          <button className="nav-button" onClick={handleNext} disabled={currentShortIndex === shorts.length - 1}>
            <FaArrowDown size={30} />
          </button>
        </div>

        {/* Titre et description en bas à gauche */}
        <div className="video-info">
          <p className="title">{currentShort?.title || 'Titre non disponible'}</p>
          <p className="description">{currentShort?.post?.legend || 'Pas de description disponible'}</p>
        </div>
      </div>
    </div>
  );
}

export default DisplayShort;
