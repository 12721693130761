import { useState } from 'react';
import { BiLike, BiSolidLike } from 'react-icons/bi';
import { FaRegCommentDots } from 'react-icons/fa';
import { VscSend } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { backendURL, backendURLFile } from '../../../../../Config/baseUrl';
import { AppDispatch } from '../../../../../Store/Store';
import {
  dicrementReactionPost,
  dislikeComment,
  incrementReactionPost,
  insertComment,
  likeComment
} from '../../../../../Toolkit/CommentAction';
import { userPost } from '../../../../../Toolkit/UsAction';
import MainModal from '../../../../Admin/Components/Modals/MainModal';

export default function PublicationUser({ data }: any) {
  const countReaction = (userId: string) => {
    const cast = JSON.parse(userId);
    return cast.length;
  };
  const [isTrue, setIsTrue] = useState(false);
  const comment = () => {
    setIsTrue(!isTrue);
  };
  const [openModal, setOpenModal] = useState(false);
  const [likecomment, setLikeComment] = useState(false);

  const [pub, setPub] = useState<any>();
  const viewDetails = (publication: any) => {
    setPub(publication);
    setOpenModal(true);
  };
  const [content1, setCommentaire1] = useState('');
  const [content2, setCommentaire2] = useState('');
  const [reaction, setReaction] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const userStorage: any = localStorage.getItem('user');
  const userToarray = JSON.parse(userStorage);


  const getCommentaire = (id: number) => {
    let content: string = '';
    try {
      if (content1 !== '') content = content1;
      if (content2 !== '') content = content2;

      dispatch(insertComment({ content, id })).then((res: any) => {
        dispatch(userPost());
        setCommentaire1('');
        setCommentaire2('');
      });
    } catch (error) {
      return error;
    }
  };

  const changeReaction = (id: number) => {
    reaction
      ? dispatch(dicrementReactionPost(id)).then((res: any) => {
        dispatch(userPost());
      })
      : dispatch(incrementReactionPost(id)).then((res: any) => {
        dispatch(userPost());
      });
  };


  const getIdUserInclude = (idUsers: string) => {
    const toArray = JSON.parse(idUsers);
    const checkId = toArray.includes(userToarray.id);
    return checkId;
  };


  const commentReaction = (idComment: number) => {
    likecomment
      ? dispatch(dislikeComment(idComment)).then((res: any) => {
        dispatch(userPost());
      })
      : dispatch(likeComment(idComment)).then((res: any) => {
        dispatch(userPost());
      });
  };

  const isVideo = (url: string) => {
    // Vérifie si l'extension de l'URL correspond à un format vidéo
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
  };

  const isImage = (url: string) => {
    // Vérifie si l'extension de l'URL correspond à un format image
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    return imageExtensions.some(ext => url.toLowerCase().endsWith(ext));
  };

  return (
    <div className="h-screen">
      {data.map((pub: any, i: number) => {
        return (
          <div key={i} className="col-span-6 rounded-md bg-main mt-5 border border-gray-800 p-6">
            <div className="flex flex-row space-x-4">
              <img
                className="h-14 w-14 rounded-full"
                alt="logo"
                src={
                  pub.user !== null && pub.user.profilePicturePath !== null
                    ? `${backendURL}/${pub.user.profilePicturePath}`
                    : process.env.PUBLIC_URL + '/Images/m.png'
                }
              />

              <div className="flex flex-col m-2">
                <div className="font-bold">{pub.user !== null && pub.user.pseudonyme}</div>
                <div>{pub.created_at}</div>
              </div>
            </div>
            <div className="m-3 font-semibold ">{pub.legend}</div>
            <div>
              {isImage(`${backendURLFile}/${pub.files[0]}`) ? (
                <img
                  className="h-96 w-full max-sm:h-52"
                  alt="media"
                  src={`${backendURLFile}/${pub.files[0]}`}
                />
              ) : isVideo(`${backendURLFile}/${pub.files[0]}`) ? (
                <video controls className="h-96 w-full max-sm:h-52">
                  <source src={`${backendURLFile}/${pub.files[0]}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p>Unsupported media type</p>
              )}
            </div>
            <div className="flex justify-between border-b-2 border-indigo-500 p-3">
              <div className="flex flex-row">
                <BiSolidLike size={30} /> <span className="m-1 text-lg">{countReaction(pub.reaction.usersId)}</span>
              </div>
              <div className="mt-1 max-sm:mt-2 cursor-pointer hover:underline underline-offset-4" onClick={comment}>
                {pub.commentNumber} commentaire
              </div>
            </div>
            <div className="flex justify-between p-3">
              {getIdUserInclude(pub.reaction.usersId) ? (
                <div
                  className="flex flex-row cursor-pointer"
                  onClick={() => {
                    setReaction(!reaction);
                    changeReaction(pub.id);
                  }}
                >
                  <BiSolidLike size={30} /> <span className="m-1 font-semibold max-sm:hidden">J'aime</span>
                </div>
              ) : (
                <div
                  className="flex flex-row cursor-pointer"
                  onClick={() => {
                    setReaction(!reaction);
                    changeReaction(pub.id);
                  }}
                >
                  <BiLike size={30} /> <span className="m-1 font-semibold max-sm:hidden">J'aime</span>
                </div>
              )}
              <button className="flex flex-row cursor-pointer" onClick={comment}>
                <FaRegCommentDots size={30} />
                <span className="m-1 font-semibold max-sm:hidden">Commentaires</span>
              </button>
            </div>
            {isTrue && (
              <div>
                {pub.comments.length > 1 && (
                  <p
                    className="font-bold text-slate-400 m-1 leading-8 cursor-pointer hover:text-white"
                    onClick={() => viewDetails(pub)}
                  >
                    Voir plus de commentaires
                  </p>
                )}
                <div>
                  {pub.comments.slice(0, 1).map((comment: any, i: number) => {
                    return (
                      <div className="flex flex-row space-x-4 ml-3 p-2" key={i}>
                        <img
                          className="h-10 w-10 rounded-full"
                          alt="avatar"
                          src={process.env.PUBLIC_URL + '/Images/m.png'}
                        />
                        <div className="flex flex-col space-y-1">
                          <div className="flex flex-col bg-dry rounded-md p-2">
                            <div className="font-bold">{comment.userId}</div>
                            <div>{comment.content}</div>
                          </div>
                          <div className="flex flex-row space-x-4 text-xs">
                            <span>{comment.created_at}</span>
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                setLikeComment(!likecomment);
                                commentReaction(comment.id);
                              }}
                            >
                              J'aime
                            </span>
                            <span className="cursor-pointer">Répondre</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-row mt-4">
                  <input
                    type="text"
                    value={content1}
                    onChange={(e) => setCommentaire1(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300
                                                placeholder:text-stone-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                                                h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500"
                    placeholder="commentaire"
                  />
                  <button className="ml-5" onClick={() => getCommentaire(pub.id)}>
                    <VscSend size={30} />
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      })}
      <div>
        {openModal && (
          <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
            <div className="inline-block sm:w-3/5 md:w-3/6 lg:w-3/6 w-full h-[550px] p-4 sticky top-14 overflow-y-auto border border-border bg-main text-white rounded-2xl">
              <div className="flex flex-col gap-6">
                {pub !== undefined && (
                  <div className="col-span-6">
                    <div className="flex flex-row space-x-4">
                      <img
                        className="h-14 w-14 rounded-full"
                        alt="logo"
                        src={
                          pub.user !== null && pub.user.profilePicturePath !== null
                            ? `${backendURL}/${pub.user.profilePicturePath}`
                            : process.env.PUBLIC_URL + '/Images/m.png'
                        }
                      />
                      <div className="flex flex-col m-2">
                        <div className="font-bold text-left text-sm">{pub.user !== null && pub.user.pseudonyme}</div>
                        <div className="text-sm">{pub.created_at}</div>
                      </div>
                    </div>
                    <div className="m-3 font-semibold text-left text-sm">{pub.legend}</div>
                    <div>
                      <img className="h-80 w-full " alt="logo" src={`${backendURLFile}/${pub.files[0]}`} />
                    </div>
                    <div className="flex justify-between border-b-2 border-indigo-500 p-3">
                      <div className="flex flex-row">
                        <BiSolidLike size={20} /> <span className="text-sm">{countReaction(pub.reaction.usersId)}</span>
                      </div>
                      <div className="text-sm">{pub.commentNumber} commentaire</div>
                    </div>
                    <div className="flex justify-between p-3">
                      <div className="flex flex-row cursor-pointer text-sm">
                        <BiLike size={20} /> <span className="font-semibold">J'aime</span>
                      </div>
                      <button className="flex flex-row cursor-pointer" onClick={comment}>
                        <FaRegCommentDots size={20} />
                        <span className="font-semibold text-sm">Commentaires</span>
                      </button>
                    </div>
                    <div>
                      <div>
                        {pub.comments.map((comment: any, i: number) => {
                          return (
                            <div className="flex flex-row space-x-4 ml-3 p-2" key={i}>
                              <img
                                className="h-10 w-10 rounded-full"
                                alt="avatar"
                                src={process.env.PUBLIC_URL + '/Images/m.png'}
                              />
                              <div className="flex flex-col space-y-1">
                                <div className="flex flex-col bg-dry rounded-md p-2">
                                  <div className="font-bold text-left text-xs">{comment.userId}</div>
                                  <div className="text-xs text-left">{comment.content}</div>
                                </div>
                                <div className="flex flex-row space-x-4 text-xs">
                                  <span>{comment.created_at}</span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setLikeComment(!likecomment);
                                      commentReaction(comment.id);
                                    }}
                                  >
                                    J'aime
                                  </span>
                                  <span className="cursor-pointer">Répondre</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-row mt-4 sticky bottom-0">
                        <input
                          value={content2}
                          onChange={(e) => setCommentaire2(e.target.value)}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300
                                                placeholder:text-stone-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                                                h-8 bg-gradient-to-r from-violet-500 to-fuchsia-500"
                          placeholder="commentaire"
                        />
                        <button className="ml-5" onClick={() => getCommentaire(pub.id)}>
                          <VscSend size={30} />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </MainModal>
        )}
      </div>
    </div>
  );
}
