import Aos from 'aos';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Abonnement from "../Presentation/Admin/Pages/Abonnement";
import AddMovie from '../Presentation/Admin/Pages/AddVideos';
import Animators from '../Presentation/Admin/Pages/Animators';
import Dashboard from '../Presentation/Admin/Pages/Dashboard';
import Emissions from '../Presentation/Admin/Pages/Emissions';
import Emission from '../Presentation/Admin/Pages/Emissions/Emissions';
import EventList from '../Presentation/Admin/Pages/EventList';
import Inscription from '../Presentation/Admin/Pages/Inscription';
import Login from '../Presentation/Admin/Pages/Login';
import Movie from "../Presentation/Admin/Pages/Movie/Movie";
import Music from '../Presentation/Admin/Pages/Music';
import Password from '../Presentation/Admin/Pages/Password';
import Profile from '../Presentation/Admin/Pages/Profile';
import Quiz from '../Presentation/Admin/Pages/Quiz/Quiz';
import Register from '../Presentation/Admin/Pages/Register';
import ShortList from '../Presentation/Admin/Pages/ShortList';
import SponsorList from '../Presentation/Admin/Pages/Sponsor';
import Teasers from '../Presentation/Admin/Pages/Teasers';
import DashboardCli from '../Presentation/Client/DashbordClient';
import AboutUs from '../Presentation/Client/Pages/AboutUs';
import AnimateurClient from '../Presentation/Client/Pages/Animateurs/AnimateurClient';
import { Fullescreen, FullescreenAnimator } from '../Presentation/Client/Pages/Components/EventTools/FullScrenn';
import HomeVideo from '../Presentation/Client/Pages/Components/Videos/Homevideo';
import ContactUs from '../Presentation/Client/Pages/ContactUs';
import Download from "../Presentation/Client/Pages/Download/Download";
import EmissionsCli from '../Presentation/Client/Pages/Emissions/EmissionsCli';
import Emissionsclients from '../Presentation/Client/Pages/Emissions/EmissionsClients';
import EventListClient from '../Presentation/Client/Pages/Events/Eventsclient';
import HomeScreen from '../Presentation/Client/Pages/Home';
import Loginclient from '../Presentation/Client/Pages/LoginClient';
import MoviesClient from "../Presentation/Client/Pages/Movies";
import DetailMovie from "../Presentation/Client/Pages/Movies/DetailMovie";
import MusiqueClient from '../Presentation/Client/Pages/MusiqueClient/MusiqueClient';
import NotFound from '../Presentation/Client/Pages/NotFound';
import PrivacyPolicyPage from '../Presentation/Client/Pages/Politic';
import { ShortClient } from '../Presentation/Client/Pages/Shorts/ShortsClient';
import SingleMovie from '../Presentation/Client/Pages/SingleVideo';
import TeaserClient from '../Presentation/Client/Pages/Teasers/TeaserClient';
import Usclient from '../Presentation/Client/Pages/Us/UsClients';
import Movies from '../Presentation/Client/Pages/Videos';
import WatchPage from '../Presentation/Client/Pages/WatchPage';
import AdminPrivateRoutes from './AdminPrivateRoute';
import ClientRoutes from './clientRouter';
import EventDetail from "../Presentation/Client/Pages/Events/component/EventDetail";
import DisplayShort from "../Presentation/Client/Pages/Shorts/DisplayShort";


function App() {
  Aos.init();
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/music" element={<Music />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/sponsor" element={<SponsorList />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/quiz" element={<Quiz />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/register" element={<Register />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/profile" element={<Profile />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/password" element={<Password />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/emission" element={<Emissions />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/event" element={<EventList />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/short" element={<ShortList />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/teaser" element={<Teasers />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/movies" element={<Movie />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/addmovie" element={<AddMovie />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/animators" element={<Animators />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/emission/:id" element={<Emission />} />
        </Route>
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/abonnement" element={<Abonnement />} />
        </Route>

        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/music" element={<MusiqueClient />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/sponsor" element={<SponsorList />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/quiz" element={<Quiz />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/register" element={<Register />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/profile" element={<Profile />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/password" element={<Password />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/emission" element={<EmissionsCli />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/event" element={<EventListClient />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/" element={<DashboardCli />} />
        </Route>
        {/* <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/short" element={<ShortClient />} />
        </Route> */}
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/teaser" element={<TeaserClient />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/addmovie" element={<AddMovie />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/animators" element={<AnimateurClient />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/nous" element={<Usclient />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/emission/:id" element={<Emissionsclients />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/emission/video" element={<HomeVideo />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/short/video" element={<HomeVideo />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/teaser/video" element={<HomeVideo />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/music/video" element={<HomeVideo />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/events/show" element={<Fullescreen />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/Animator/about" element={<FullescreenAnimator />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/movies" element={<MoviesClient />} />
        </Route>
        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/movies/details/:id" element={<DetailMovie />} />
        </Route>

        <Route element={<ClientRoutes />}>
          <Route path="/dashboard/client/download" element={<Download />} />
        </Route>

        <Route path="/dashboard/client/event/:id" element={<EventDetail />} />
        <Route path="/dashboard/client/short" element={<DisplayShort />} />


        <Route path="/" element={<HomeScreen />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/movies" element={<Movies />} />
        <Route path="/politic" element={<PrivacyPolicyPage />} />
        <Route path="/movies/:id" element={<SingleMovie />} />
        <Route path="/watch/:id" element={<WatchPage />} />
        <Route path="/dashboard/login" element={<Login />} />
        <Route path="/login" element={<Loginclient />} />
        <Route path="/inscription" element={<Inscription />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
