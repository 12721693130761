import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { backendURL } from '../Config/baseUrl';
import { notifURL } from '../Config/baseUrl';

interface event {
  legend: string;
  file1?: string;
  date: string | Date;
  place: string;
  ticketPlace: string;
  important: boolean;
}
interface eventInfo {
  event: event;
  id: number;
}
interface eventState {
  loading: boolean;
  lists: Array<event>;
  error: string | null;
  message: string | null;
  selectedEvent: event | null;
}
export const GetAllevents = createAsyncThunk('events/all', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/post/all_event_posters`, config);
    return data.eventPosters.reverse();
  } catch (error: any) {
    return error.message;
  }
});

export const getEventById = createAsyncThunk('events/byId', async (eventId: number) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/post/event/${eventId}`, config);
    return data;
  } catch (error: any) {
    return error.message;
  }
});

export const Createevent = createAsyncThunk('events/add', async (eventInfo: event, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/post/new_event_poster`, eventInfo, config);
      try {
        await axios.post(`${notifURL}/notif/event`).then((res) => {
          return res;
        });
      } catch (error) {
        return error;
      }
      return data.message;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const UpdateEvent = createAsyncThunk('events/update', async (eventInfo: eventInfo, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.put(
        `${backendURL}/post/event_poster/update/${eventInfo.id}`,
        eventInfo.event,
        config
      );
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const DeleteEv = createAsyncThunk('events/delete', async (id: number, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/post/event_poster/delete/${id}`, config);
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const initialState: eventState = {
  loading: false,
  lists: [],
  error: null,
  message: null,
  selectedEvent: null
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllevents.pending, (state: eventState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllevents.fulfilled, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.lists = payload;
    });
    builder.addCase(GetAllevents.rejected, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(Createevent.pending, (state: eventState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Createevent.fulfilled, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre evenement avec succèss';
    });
    builder.addCase(Createevent.rejected, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(UpdateEvent.pending, (state: eventState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(UpdateEvent.fulfilled, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.message = 'Mise à jour de votre evenement avec succèss';
    });
    builder.addCase(UpdateEvent.rejected, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(DeleteEv.pending, (state: eventState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(DeleteEv.fulfilled, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression de votre evenement avec succèss';
    });
    builder.addCase(DeleteEv.rejected, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getEventById.pending, (state: eventState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEventById.fulfilled, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.selectedEvent = payload;
    });
    builder.addCase(getEventById.rejected, (state: eventState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = eventSlice.actions;
export default eventSlice.reducer;
