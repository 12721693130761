import React, { useEffect, useState, useRef } from 'react';
import { ETypeVideo } from "../enum";
import { streamingUrlFile } from "../../Config/baseUrl";
import Cookies from "js-cookie";

interface VideoPlayersProps {
  typeVideo: ETypeVideo;
  nameFile: string;
  tokenPlay: string;
  onAddViewVideo?: () => void;
}

const VideoPlayers: React.FC<VideoPlayersProps> = ({ typeVideo, nameFile, tokenPlay, onAddViewVideo }) => {
  /**
   * VARIABLES
   */
  let idmCheckInterval: number | undefined;
  const [videoSrc, setVideoSrc] = useState<string>(""); // Stocker l'URL actuelle de la vidéo
  const videoRef = useRef<HTMLVideoElement>(null); // Référence à l'élément vidéo

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    if (nameFile && tokenPlay && typeVideo) {
      const newSrc = `${streamingUrlFile}/video/${typeVideo}/${nameFile}/${tokenPlay}`;
      setVideoSrc(newSrc);
    }
  }, [nameFile, tokenPlay, typeVideo]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load(); // Recharger la vidéo quand la source change
    }
  }, [videoSrc]); // Recharger la vidéo quand la source change

  /**
   * FUNCTION
   */
  const checkForIDM = () => {
    const videos = Array.from(document.getElementsByTagName('video')); // Convert HTMLCollection to array
    for (const video of videos) {
      if (video.hasAttribute('__idm_id__')) {
        video.removeAttribute('__idm_id__')
        return true; // IDM est probablement actif
      }
    }
    return false; // IDM non détecté
  };

  const blockContent = () => {
    // Si IDM est détecté, changer l'URL à une URL factice
    if (videoRef.current) {
      // setVideoSrc('https://example.com/fakevideo.mp4'); // Changer l'URL à une vidéo factice
      console.warn("IDM détecté. Vidéo bloquée avec une URL factice.");
    }
  };

  useEffect(() => {
    const startIDMCheck = () => {
      idmCheckInterval = window.setInterval(() => {
        if (checkForIDM()) {
          // clearInterval(idmCheckInterval);
          // blockContent();
        }
      }, 1000); // Vérification chaque seconde
    };

    // Démarrer immédiatement après le montage du composant
    startIDMCheck();

    // Nettoyer l'intervalle lors du démontage du composant
    return () => {
      if (idmCheckInterval !== undefined) {
        clearInterval(idmCheckInterval);
      }
    };
  }, []); // [] indique que cet effet ne se déclenche qu'une fois après le montage

  return (
    <div className="video-container">
      <video ref={videoRef} className="w-full max-w-3xl mx-auto" controls onPlay={onAddViewVideo}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayers;
