import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { FcLeft, FcRight } from "react-icons/fc";  // Flèches pour Previous et Next
import { AiOutlineClose } from "react-icons/ai";   // Icône de fermeture
import { backendURLFile } from "../../../../../Config/baseUrl";
import "../core/eventDetail.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Store/Store";
import { GetAllevents, getEventById } from "../../../../../Toolkit/EventAction";

const EventDetail: React.FC = () => {
  /**
   * REDUX
   */
  const dispatch = useDispatch<AppDispatch>();
  const detailEvents = useSelector((state: any) => state.event.selectedEvent);
  const eventsList = useSelector((state: any) => state.event.lists);

  /**
   * VARIABLE
   */
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    if (id) {
      dispatch(getEventById(+id));  // Récupérer l'événement basé sur l'ID de l'URL
      dispatch(GetAllevents());  // Récupérer la liste complète des événements
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (detailEvents?.data) {
      setSelectedEvent(detailEvents.data);
    }
  }, [detailEvents]);

  /**
   * HANDLERS FOR NEXT AND PREVIOUS
   */
  const handleNext = () => {
    if (!id) return
    if (eventsList && eventsList.length > 0) {
      // Trouver l'index actuel
      const currentIndex = eventsList.findIndex((event: any) => event.id === +id);
      if (currentIndex !== -1 && currentIndex < eventsList.length - 1) {
        // Récupérer l'événement suivant
        const nextEvent = eventsList[currentIndex + 1];
        navigate(`/dashboard/client/event/${nextEvent.id}`);  // Mettre à jour l'URL avec le nouvel ID
      }
    }
  };

  const handlePrevious = () => {
    if (!id) return
    if (eventsList && eventsList.length > 0) {
      // Trouver l'index actuel
      const currentIndex = eventsList.findIndex((event: any) => event.id === +id);
      if (currentIndex > 0) {
        // Récupérer l'événement précédent
        const previousEvent = eventsList[currentIndex - 1];
        navigate(`/dashboard/client/event/${previousEvent.id}`);  // Mettre à jour l'URL avec le nouvel ID
      }
    }
  };

  const closeDetailEvent = () => {
    navigate(`/dashboard/client/event`)
  }

  return (
    <div className="container-event bg-main">
      {/* Bouton Fermer en haut à droite */}
      <button className="close-button" onClick={closeDetailEvent}>
        <AiOutlineClose size={30} /> {/* Icône Fermer */}
      </button>
      {/* Bouton Previous */}
      <button className="previous" onClick={handlePrevious} disabled={!eventsList || eventsList.length === 0}>
        <FcLeft size={40} /> {/* Icône Previous */}
      </button>
      <div className="container-image-1">
        <div className="container-image">
          <img
            className="image"
            src={`${backendURLFile}/${selectedEvent?.post?.files[0]}`}
            style={{ objectFit: 'cover' }}
            alt="Event"
          />
          <div className="info">
            <p>Le {selectedEvent?.date} à {selectedEvent?.place}</p>
            <p>{selectedEvent?.ticketPlace}</p>
            <p>{selectedEvent?.post?.created_at}</p>
          </div>
        </div>
      </div>
      {/* Bouton Next */}
      <button className="next" onClick={handleNext} disabled={!eventsList || eventsList.length === 0}>
        <FcRight size={40} /> {/* Icône Next */}
      </button>
    </div>
  );
}

export default EventDetail;
