import axios from 'axios';
import { backendURL } from '../Config/baseUrl';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface commentInterface {
  content: string | null;
  postId: number | null;
  userId: any;
  replyTo: string | null;
  message: string | null;
  error: string | null;
  loading: boolean;
}

interface commentWithId {
  content: string;
  id: number;
}

export const insertComment = createAsyncThunk('comment/post', async (comment: commentWithId, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('clientToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/comment/${comment.id}`, { content: comment.content }, config);
      return data.Message;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const incrementReactionPost = createAsyncThunk(
  '/post/reaction/increment',
  async (postId: number, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('clientToken')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
          }
        };
        const { data } = await axios.put(`${backendURL}/post/increment/${postId}`, {}, config);
        return data?.post;
      } else {
        throw new Error('Le jéton est périmé,veuillez reconnecter');
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const dicrementReactionPost = createAsyncThunk(
  '/post/reaction/decrement',
  async (postId: number, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('clientToken')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
          }
        };
        const { data } = await axios.put(`${backendURL}/post/decrement/${postId}`, {}, config);
        return data?.post;
      } else {
        throw new Error('Le jéton est périmé,veuillez reconnecter');
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const tootgleReactionPost = createAsyncThunk(
  '/post/reaction/toogle',
  async (postId: number, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('clientToken')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
          }
        };
        const { data } = await axios.put(`${backendURL}/post/decrement/toogle_reaction/${postId}`, {}, config);
        return data?.post;
      } else {
        throw new Error('Le jéton est périmé,veuillez reconnecter');
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const likeComment = createAsyncThunk(
  'incrementCommentaryReaction',
  async (commentId: number, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('clientToken')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
          }
        };
        const { data } = await axios.put(`${backendURL}/comment/increment/${commentId}`, {}, config);
        return data.Message;
      } else {
        throw new Error('Le jéton est périmé,veuillez reconnecter');
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const dislikeComment = createAsyncThunk(
  'decrementCommentaryReaction',
  async (commentId: number, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('clientToken')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
          }
        };
        const { data } = await axios.put(`${backendURL}/comment/decrement/${commentId}`, {}, config);
        return data.Message;
      } else {
        throw new Error('Le jéton est périmé,veuillez reconnecter');
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const initialState: commentInterface = {
  content: null,
  postId: null,
  userId: null,
  replyTo: null,
  message: null,
  error: null,
  loading: false,

};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(insertComment.pending, (state: commentInterface, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(insertComment.fulfilled, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre commentaire avec succèss';
    });
    builder.addCase(insertComment.rejected, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(incrementReactionPost.pending, (state: commentInterface, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(incrementReactionPost.fulfilled, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre commentaire avec succèss';
    });
    builder.addCase(incrementReactionPost.rejected, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(dicrementReactionPost.pending, (state: commentInterface, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(dicrementReactionPost.fulfilled, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre commentaire avec succèss';
    });
    builder.addCase(dicrementReactionPost.rejected, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(likeComment.pending, (state: commentInterface, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(likeComment.fulfilled, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre commentaire avec succèss';
    });
    builder.addCase(likeComment.rejected, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(dislikeComment.pending, (state: commentInterface, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(dislikeComment.fulfilled, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre commentaire avec succèss';
    });
    builder.addCase(dislikeComment.rejected, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(tootgleReactionPost.rejected, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(tootgleReactionPost.pending, (state: commentInterface, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(tootgleReactionPost.fulfilled, (state: commentInterface, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });


  }
});
export const { seterrornull, setMessageNull } = commentSlice.actions;
export default commentSlice.reducer;
